import React from 'react';
import ServicesCard from './ServicesCard';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsServicesEngineering {
	className?: string;
}

function ServicesEngineering({ className }: propsServicesEngineering) {
	const { t } = useTranslation();

	const dataServices = t('services.data-services', {
		returnObjects: true,
	});

	return (
		<section className={`services-engineering ${className}`}>
			<ul className="services-engineering__cards">
				{Array.from(dataServices).map((data: any, index: number) => (
					<ServicesCard
						key={index}
						icon={`${data.icon}`}
						className="services-engineering__card"
						list={data.list}
						subList={data.subList}
					>
						<h4>{data.title}</h4>
					</ServicesCard>
				))}
			</ul>
		</section>
	);
}

export default ServicesEngineering;
