import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Title from '../components/Title';
import Slider from '../components/Slider';
import Table from '../components/Table';
import Map from '../components/Map';
import useProjects from '../services/service.projects';
import { finalTable, project, projectTable } from '../typings/projects';
// import { PROJECT_INSIDE } from '../consts/common';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';
import { useDocumentTitle } from '../hooks';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../store/slices/titleSlice';
import { useDispatch } from 'react-redux';
import useBindActionCreators from '../hooks/useBindActionCreators';
import { categoriesActions } from '../store/slices/categoriesSlice';

function ProjectInside() {
	const { t } = useTranslation();
	useDocumentTitle(t('projects.title'));

	const { language } = useStore();

	const { getProject } = useProjects();
	const { id: projectId } = useParams();
	const [project, setProject] = useState<project>();
	// const [table, setTable] = useState<finalTable>([]);
	const [_, setTableWrapper] = useState<string>('');
	const { setActiveCategory } = useBindActionCreators({ ...categoriesActions });
	const dispatch = useDispatch();
	let lat = +(project?.project?.point?.latitude?.replace(/,/,'.') as unknown as any) ? +(project?.project?.point?.latitude.replace(/,/,'.') as unknown as any) : 0
	let lng = +(project?.project?.point?.longitude?.replace(/,/,'.') as unknown as any) ? +(project?.project?.point?.longitude.replace(/,/,'.') as unknown as any) : 0
	useEffect(() => {
		const container = document.getElementById('project-text');
		if (container) {
			const tables = container.querySelectorAll('table');

			if (tables.length > 0) {
				const blockForTable = document.createElement('div');
				blockForTable.classList.add('table__wrapper');
				blockForTable.appendChild(tables[0]);

				container.appendChild(blockForTable);
				setTableWrapper(container.innerHTML);
			}
		}
	}, [project]);

	useEffect(() => {
		if (projectId && !project) {
			getProject(projectId, language).then((data) => {
				setProject(data);
				setActiveCategory({category: {
					id: data?.project?.category.id
				}, 
				name: data?.project?.category.translation.name,
				language: {
					code: data?.project?.category.translation.language.code
				},
				isEnabled: data?.project?.category.translation.isEnabled
				});
				dispatch(setTitle(data.title));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			dispatch(setTitle(""))
		}
	}, [])
	
	let translationSlug
	let checkIfLanguageCreated = (project:any) => {
		return project?.project?.translations?.some((el: { language: { code: string; }; }) => 
			el?.language?.code === language)
	}

	useEffect(() => {
		if(language !== project?.language?.code && project && checkIfLanguageCreated(project)) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			translationSlug = project?.project?.translations?.filter((el: { language: { code: string; }, slug: string }) => {
				if (el.language.code === language) {
					return el
				}
			})
			getProject(`${translationSlug[0]?.slug ? translationSlug[0]?.slug : project.slug }`, language).then((data) =>  {
				setProject(data)
				setActiveCategory({category: {
						id: data?.project?.category.id
					}, 
					name: data?.project?.category.translation.name,
					language: {
						code: data?.project?.category.translation.language.code
					},
					isEnabled: data?.project?.category.translation.isEnabled
				});
				dispatch(setTitle(data.title));
			});	
			window.history.replaceState( null , '', `${translationSlug[0]?.slug ? translationSlug[0]?.slug : project.slug }`);
		}
	}, [project?.project?.translations, project?.language?.code, language, translationSlug]);

	return (
		<section className="project-inside">
			<ScrollToTopOnMount />
			<div className="container">
				<Title size="middle" className="project-inside__title">
					{project?.title}
				</Title>
				<div className="project-inside__body">
					<div className="project-inside__slider-text">
							{!!project?.images.length && (
								<div className="project-inside__slider-wrapper">
									<Slider
										buttonSize="big"
										data={project?.images}
										className="project-inside__slider"
									/>
								</div>
							)}
						<div
							id="project-text"
							className="project-inside__info"
							contentEditable="false"
							dangerouslySetInnerHTML={{
								__html: project?.body as unknown as string,
							}}
						/>
					</div>
					<div className="project-inside__row">
							{project?.properties?.length as number > 0 && (	
								<div className="project-inside__table">
									<Table data={project?.properties} />
								</div>)}
							{Boolean(lat && lng) && (
								<div className="project-inside__map">
									<Map coords={{ lat, lng }} />
								</div>
							)}
					</div>
				</div>
			</div>
		</section>
	);
}

export default ProjectInside;

