import photo1 from '../img/management/1.png';
import photo2 from '../img/management/2.jpg';
import photo3 from '../img/management/3.png';
import photo4 from '../img/management/4.png';

export const management = [
	{
		photo: photo1,
		fullName: 'Крайник Вадим Ярославович',
		position: 'Генеральний директор',
	},
	{
		photo: photo2,
		fullName: 'Жакун Анатолій Миколайович',
		position: '1-й заступник Генерального директора',
	},
	{
		photo: photo3,
		fullName: 'Погосян Сергій Миколайович',
		position: 'Технічний директор',
	},
	{
		photo: photo4,
		fullName: 'Погрібняк Юрій Сергійович',
		position: 'Директор виконавчий',
	},
];
