import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PROJECTS } from '../helpers/routes';
import Title from './Title';
import ButtonLink from './ButtonLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import useProjects from '../services/service.projects';
import useStore from '../store/selectors/useStore';
import useRedirectToItem from '../hooks/useRedirectToItem';
import projectEmptyPhoto from '../img/projects/project-empty-photo.png';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import {  imageSrcProject } from '../helpers/imageSrc';
import {project as Project} from '../../src/typings/projects'
import {projects as Projects} from '../../src/typings/projects'


interface propsMainProjects {
	className?: string;
}

const settings = {
	speed: 500,
	watchSlidesProgress: true,
	autoplay: true,
	spaceBetween: 0,
	initialSlide: 1,
	slideActiveClass: 'main-projects__slide-active',
	slidePrevClass: 'main-projects__slide-prev',
	slideNextClass: 'main-projects__slide-next',
	slideVisibleClass: 'main-projects__slide-visible',
	slideToClickedSlide: true,
	loop: true,
	loopedSlides: 3,
	navigation: {
		nextEl: '.main-projects__prev',
		prevEl: '.main-projects__next',
	},
};

function MainProjects({ className }: propsMainProjects) {
	const { t } = useTranslation();
	const { language } = useStore();

	const { getAllProjects } = useProjects();
	const { projects } = useStore();
	const redirect = useRedirectToItem();
	const [mainProjects, setMainProjects] = useState<Projects>([])
	
	useEffect(() => {
		getAllProjects(language).then((data) => {
			const RivneNPP = data.projects.find((project :Project) => project.project.id === "4") || {}
			const ZaporizhzhiaNPP = data.projects.find((project :Project) => project.project.id === "8") || {}
			const PrydniprovskaTPP = data.projects.find((project :Project) => project.project.id === "22") || {}
			const KarahandynskaGRES = data.projects.find((project :Project) => project.project.id === "86") || {}
			const electricSubstation =  data.projects.find((project :Project) => project.project.id === "61") || {}
			setMainProjects([RivneNPP, ZaporizhzhiaNPP, PrydniprovskaTPP, KarahandynskaGRES, electricSubstation])
		}
		);
	}, [language]);


	return (
		<section className={`main-projects ${className}`}>
			<div className="container">
				<div className="main-projects__wrapper">
					<div className="main-projects__header">
						<Title size="middle" className="main-projects__title">
							{t('main.projects.title')}
						</Title>
						<Link
							to={PROJECTS}
							className="main-projects__button button-link button-link--red"
						>
							{t('main.projects.see-all')}
						</Link>
					</div>

					<div className="main-projects__body">
						<Swiper
							{...settings}
							slidesPerView="auto"
							centeredSlides={true}
							autoplay={true}
							speed={500}
							watchSlidesProgress={true}
							modules={[Pagination, Autoplay, Navigation]}
							pagination={{
								type: 'bullets',
								el: '.main-projects__bullets',
							}}
							className="main-projects__slider"
						>
							{mainProjects?.map((project, index) => (
								<SwiperSlide key={index} className="main-projects__slide">
									<div className="main-projects__slide-body">
										<div className="main-projects__slide-image">
											<img
												src={project?.images?.length ? imageSrcProject(project) : projectEmptyPhoto}
												alt=""
											/>
										</div>
										<h6 className="main-projects__slide-title">
											{project.title}
										</h6>
										<ButtonLink
											onClick={() => redirect(PROJECTS, project.slug as any)}
											color="black"
											className="main-projects__slide-button"
										>
											{t('main.buttons.to-project')}
										</ButtonLink>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
						<div className="main-projects__navigation">
							<button type="button" className="main-projects__next" />
							<button type="button" className="main-projects__prev" />
						</div>
						<div className="main-projects__bullets" />
					</div>
				</div>
				<Link
					to={PROJECTS}
					className="main-projects__button button-link button-link--red main-projects__button--mobile"
				>
					Дивитися усі проекти
				</Link>
			</div>
		</section>
	);
}

export default MainProjects;
