import React from 'react';
import iconFacebook from '../img/icons/social/facebook.svg';
import iconInstagram from '../img/icons/social/instagram.svg';
import iconYoutube from '../img/icons/social/youtube.svg';
import iconGoogle from '../img/icons/social/google.svg';

interface propsContactSocial {
	className?: string;
}

function ContactSocial({ className }: propsContactSocial) {
	return (
		<ul className={`contact-social ${className}`}>
			<li className="contact-social__item">
				<a
					href="https://www.facebook.com/Virtual-Design-Bureau-Ukraine-106887037789802"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconFacebook}
						alt="facebook"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.instagram.com/ukrhydroproject/"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconInstagram}
						alt="instagram"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.youtube.com/channel/UCGb_695qQu15YUXLPIn9yNg"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconYoutube}
						alt="youtube"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.google.com/search?q=%D1%83%D0%BA%D1%80%D0%B0%D1%82%D0%BE%D0%BC%D0%B5%D0%BD%D0%B5%D1%80%D0%B3%D0%BE%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82&sxsrf=APwXEddnLVNE2PwtWV6HHj8Al0H1s9K7EA%3A1687781124385&ei=BH-ZZJr1FvOP9u8PgJqk8A0&ved=0ahUKEwiah4LT8uD_AhXzh_0HHQANCd4Q4dUDCA8&uact=5&oq=%D1%83%D0%BA%D1%80%D0%B0%D1%82%D0%BE%D0%BC%D0%B5%D0%BD%D0%B5%D1%80%D0%B3%D0%BE%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzILCC4QgAQQxwEQrwEyBggAEAUQHjIZCC4QgAQQxwEQrwEQlwUQ3AQQ3gQQ4AQYAUoECEEYAFAAWABg0wFoAHAAeACAAVyIAVySAQExmAEAoAECoAEBwAEB2gEGCAEQARgU&sclient=gws-wiz-serp"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img src={iconGoogle} alt="google" className="contact-social__logo" />
				</a>
			</li>
		</ul>
	);
}

export default ContactSocial;
