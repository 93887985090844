import { useDispatch } from 'react-redux';
import useStore from '../store/selectors/useStore';
import { setActiveCountry } from '../store/slices/countriesSlice';
import '../utils/i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface propsProjectsFilterCountry {
	className?: string;
}

function ProjectsFilterCountry({ className }: propsProjectsFilterCountry) {
	const { countries, activeCountry, language } = useStore();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	let properText = useCallback((country) => {
		if(!country) {
			return t('projects.all')
		}
			return country
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<ul className={`projects-filter-country ${className}`}>
			{countries.map((item) => (
				<li className="projects-filter-country__item" key={item.id}>
					<span
						onClick={() => dispatch(setActiveCountry(item?.country?.id))}
						className={`projects-filter-country__link ${
							activeCountry === item.country?.id ? 'active' : ''
						}`}
					>
						{properText(item.name)}
					</span>
				</li>
			))}
		</ul>
	);
}

export default ProjectsFilterCountry;
