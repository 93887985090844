import React from 'react';
import MainAboutInfo from './MainAboutInfo';
import exampleImage from '../img/mainHero/1.png';

interface propsMainHero {
	className?: string,
}

function MainHero({ className }: propsMainHero) {
	return (
		<section className={`main-hero ${className}`}>
			<div className="main-hero__body">
				<div className="main-hero__background">
					<img
						src={exampleImage}
						alt="example" />
				</div>
				<MainAboutInfo
					className="main-hero__about-info"
				/>
			</div>
		</section>
	);
}

export default MainHero;
