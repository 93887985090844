import React, { ReactNode } from 'react';
import ContactSocial from './ContactSocial';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsFooterContact {
	className?: string;
	children?: ReactNode;
}

function FooterContact({ className, children }: propsFooterContact) {
	const { t } = useTranslation();
	return (
		<div className={`footer-contact ${className}`}>
			<div className="footer-contact__body">
				{children}
				<ul className="footer-contact__list">
					<li className="footer-contact__item">
						<address className="footer-contact__address">
							{t('footer.contact.address.first-part')}
							<br /> {t('footer.contact.address.second-part')}
						</address>
					</li>
					<li className="footer-contact__item">
						<a href="tel:+380577021481" className="footer-contact__link">
						+38 057 702-14-81
						</a>
						{/* ;
						<a href="tel:+380577175705" className="footer-contact__link">
							717 57 05
						</a> */}
					</li>
					{/* <li className="footer-contact__item">
						<a href="tel:+380577143916" className="footer-contact__link">
							{t('footer.contact.fax')} +38 (057) 714 39 16
						</a>
					</li> */}
					<li className="footer-contact__item">
						<a href="mailto:atom@uaep.com.ua" className="footer-contact__link">
							atom@uaep.com.ua
						</a>
					</li>
					<li className="footer-contact__item">
						<ContactSocial className="footer-contact__social" />
					</li>
				</ul>
			</div>
		</div>
	);
}

export default FooterContact;
