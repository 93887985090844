import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks';
import ProjectsDropdownList from './ProjectsDropdownList';
import Title from './Title';

import useStore from '../store/selectors/useStore';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';

function ProjectsDropdown() {
	const refDropdown = useRef<HTMLDivElement>(null);
	const refTitle = useRef<HTMLHeadingElement>(null);
	const refDropdownList = useRef<HTMLUListElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const { activeCategory, language } = useStore();

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};

	useOnClickOutside(refDropdown, handleCloseDropdown);

	return (
		<div
			ref={refDropdown}
			className={
				isDropdownOpen ? 'projects-dropdown open' : 'projects-dropdown'
			}
		>
			<Title
				refTitle={refTitle}
				size="middle"
				className="projects-dropdown__title"
				onClick={() => {
					isDropdownOpen ? setIsDropdownOpen(false) : setIsDropdownOpen(true);
				}}
			>
				{activeCategory?.name}
			</Title>

			<ProjectsDropdownList
				refList={refDropdownList}
				closeDropdown={handleCloseDropdown}
				className={`projects-dropdown__list  ${
					refTitle.current && refTitle.current?.offsetWidth < 450
						? 'projects-dropdown__list--left-side'
						: ''
				}`}
			/>
		</div>
	);
}

export default ProjectsDropdown;
