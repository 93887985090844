import React, { ReactNode, useEffect, useState } from 'react';
import ButtonLink from './ButtonLink';
import { vacancies, vacancy } from '../typings/vacancies';
import useRedirectToItem from '../hooks/useRedirectToItem';
import { VACANCIES } from '../helpers/routes';

import { useTranslation } from 'react-i18next';
import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';
import useVacancies from '../services/service.vacancies';

interface propsSpoiler {
	children?: ReactNode;
	className?: string;
	fetchData?: () => Promise<vacancies>;
	onClick: (event: any) => void;
	workspace: string
}

function Spoiler({ children, className, onClick, workspace }: propsSpoiler) {
	const { language } = useStore();
	const redirect = useRedirectToItem();
	const { t } = useTranslation();
	const { getAllVacancies} = useVacancies()

	const [otherSiteVacancies, setOtherSiteVacancies] = useState<vacancy[]>([]);

	useEffect(() => {
		if (language) {
			getAllVacancies(language, workspace).then((data : vacancies) => {
				setOtherSiteVacancies(data.vacancies);
			});
		}

		return () => {
			setOtherSiteVacancies([]);
		  };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<div className={`spoiler ${className}`}>
			<h6 onClick={onClick} className="spoiler__title">
				{children}
				<span className="spoiler__title-arrow" />
			</h6>

			<div className="spoiler__content">
				{otherSiteVacancies.map((spoiler, index) => (
					<div key={index} className="spoiler__content-row">
						<div className="spoiler__content-col spoiler__content-col--title">
							<span className="spoiler__content-title">
								{spoiler?.title}
							</span>
						</div>
						<div className="spoiler__content-col spoiler__content-col--description">
							<div
								className="spoiler__content-description"
								contentEditable="false"
								dangerouslySetInnerHTML={{
									__html: `${spoiler?.body}`,
								}}
							/>
						</div>
						<div className="spoiler__content-col spoiler__content-col--button">
							<ButtonLink
								color="blue"
								className="spoiler__content-button"
								onClick={() => redirect(`${VACANCIES}/${spoiler.slug}`)}
							>
								{t('vacancies.buttons.to-vacancy')}
							</ButtonLink>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Spoiler;
