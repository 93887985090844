import React from 'react';
import iconPhone from '../img/icons/phone.svg';
import iconMail from '../img/icons/mail.svg';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsContactReception {
	className?: string;
}

function ContactReception({ className }: propsContactReception) {
	const { t } = useTranslation();

	return (
		<div className={`contact-reception ${className}`}>
			<h3 className="contact-reception__title">
				{t('footer.reception.title')}:
			</h3>
			<ul className="contact-reception__list">
				<li className="contact-reception__item">
					<a href="tel:+380577021481">
						<img
							src={iconPhone}
							alt="phone icon"
							className="contact-reception__icon"
						/>
						+38 057 702-14-81
					</a>
				</li>
				{/* <li className="contact-reception__item">
					<a href="tel:+380577175705">
						<img
							src={iconPhone}
							alt="phone icon"
							className="contact-reception__icon"
						/>
						+38 (057) 717 57 05
					</a>
				</li> */}
				{/* <li className="contact-reception__item">
					<a href="tel:+380577175705">
						<img
							src={iconPhone}
							alt="phone icon"
							className="contact-reception__icon"
						/>
						{t('footer.contact.fax')} +38 (057) 714 39 16
					</a>
				</li> */}
				<li className="contact-reception__item">
					<a href="mailto:atom@uaep.com.ua">
						<img
							src={iconMail}
							alt="mail icon"
							className="contact-reception__icon"
						/>
						atom@uaep.com.ua
					</a>
				</li>
			</ul>
		</div>
	);
}

export default ContactReception;
