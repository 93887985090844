import { useCallback } from 'react';
import useHttp from './httpService';

const useNews = () => {
	const { getAll, getAllWithPagination, getOne, getPinned } = useHttp();
	const _baseUrl = `${process.env.REACT_APP_API_URL}`
	const getAllNews = useCallback((language) => {
		getAll('article', 'GET', null,
	{'Accept-Language': `${language}`, 
	'X-Workspace': 'uaep',
	'Access-Control-Allow-Origin': `${_baseUrl}`})
	}
	, [getAll]);

	const getAllNewsWithPagination = useCallback(
		(page: number, language = 'uk') => getAllWithPagination('article', 'GET', null,
		{'Accept-Language': `${language}`, 
		'X-Workspace': 'uaep',
		'Access-Control-Allow-Origin': `${_baseUrl}`}, page),
		[getAllWithPagination]
	);
	const getNews = (articleTranslationSlug: string, language = 'uk') => getOne(`/article/translation/${articleTranslationSlug}/get`, 
		'GET',
		null,
		{'Accept-Language': `${language}`, 
		'X-Workspace': 'uaep',
		'Access-Control-Allow-Origin': `${_baseUrl}`});

	const getPinnedNews = (language: string) => getPinned('article', 'GET', null,
		{'Accept-Language': `${language}`, 
		'X-Workspace': 'uaep',
		'Access-Control-Allow-Origin': `${_baseUrl}`});

	return { getAllNews, getAllNewsWithPagination, getNews, getPinnedNews };
};

export default useNews;
