import ArrowLink from './ArrowLink';
import { Link } from 'react-router-dom';
import { propsVacanciesList } from '../typings/vacancies';
import { VACANCIES } from '../helpers/routes';
import '../utils/i18next';
import { setTitle } from '../store/slices/titleSlice';
import { useDispatch } from 'react-redux';

function VacanciesList({ className, data, setCurrentVacancy }: propsVacanciesList) {
	const dispatch = useDispatch();
	return (
		<ul className={`vacancies-list ${className}`}>
			{data.map((item: any, index) => (
				<Link
					key={index}
					to={`${VACANCIES}/${item.slug}`}
					onClick={() => {
						setCurrentVacancy(item)
						dispatch(setTitle(item.title))
					}}
					className={`vacancies-list__item ${className}`}
				>
					<h5 className="vacancies-list__title">
						{item.title}
					</h5>
					<div className="vacancies-list__arrow-link">
						<ArrowLink color="blue" />
					</div>
				</Link>
			))}
		</ul>
	);
}

export default VacanciesList;
