import React from 'react';
import ContactSocial from './ContactSocial';
import iconMail from '../img/icons/mail.svg';
import iconPhone from '../img/icons/phone.svg';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsContactMarketing {
	className?: string;
}

function ContactMarketing({ className }: propsContactMarketing) {
	const { t } = useTranslation();

	return (
		<div className={`contact-marketing ${className}`}>
			<h3 className="contact-marketing__title">
				{t('footer.marketing.title')}:
			</h3>
			<ul className="contact-marketing__list">
				<li className="contact-marketing__item">
					<a href="tel:+380577021518">
						<img
							src={iconPhone}
							alt="phone icon"
							className="contact-marketing__icon"
						/>
						+38 057 702-15-18
					</a>
				</li>
				<li className="contact-marketing__item">
					<a href="mailto:marketing@uaep.com.ua">
						<img
							src={iconMail}
							alt="phone icon"
							className="contact-marketing__icon"
						/>
						marketing@uaep.com.ua
					</a>
				</li>
				<li className="contact-marketing__item">
					<ContactSocial />
				</li>
			</ul>
		</div>
	);
}

export default ContactMarketing;
