import React from 'react';
import { vacanciesOfferList } from '../data/vacanciesOfferList';

import { useTranslation } from 'react-i18next';
import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';

function VacanciesOffer() {
	const { language } = useStore();

	const { t } = useTranslation();

	return (
		<div className="vacancies-offer">
			<h6 className="vacancies-offer__title">
				{t('vacancies.vacanciesOffer.header')}
			</h6>
			<ul className="vacancies-offer__list">
				{vacanciesOfferList.map((offer, index) => (
					<li key={index} className="vacancies-offer__item">
						{getLocaleProperty(offer, 'li', language)}
					</li>
				))}
			</ul>
			<div className="vacancies-offer__contact">
				<div className="vacancies-offer__contact-col">
					<h6 className="vacancies-offer__contact-title">
						{t('vacancies.vacanciesOffer.contact.header.first')}
						{t('vacancies.vacanciesOffer.contact.header.second')}
					</h6>
					{/* <div className="vacancies-offer__contact-phone">
						<a href="tel:+380577175712">+38 (057) 717 57 12</a>
						<span>
							{t('vacancies.vacanciesOffer.contact.phone-desc.first')}
						</span>
					</div> */}
					<div className="vacancies-offer__contact-phone">
						<a href="tel:+380577021481">+38 057 702-14-81</a>
						<span>
							{t('vacancies.vacanciesOffer.contact.phone-desc.second')}
						</span>
					</div>
				</div>
				<div className="vacancies-offer__contact-col">
					<h6 className="vacancies-offer__contact-title">
						{t('vacancies.vacanciesOffer.contact.email')}
					</h6>
					<a
						href="mailto:atom@uaep.com.ua"
						className="vacancies-offer__contact-link"
					>
						atom@uaep.com.ua
					</a>
				</div>
			</div>
		</div>
	);
}

export default VacanciesOffer;
