import React, { useState } from 'react';
import { tabs } from '../data/tabs';
import { Link } from 'react-router-dom';
import Title from './Title';
import exampleImage1 from '../img/mainAbout/1.png';
import exampleImage2 from '../img/mainAbout/2.png';
import { ABOUT } from '../helpers/routes';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function MainAbout() {
	const { t } = useTranslation();

	return (
		<section className="main-about">
			<div className="container">
				<div className="main-about__body">
					<div className="main-about__images">
						<div className="main-about__image main-about__image--large">
							<img src={exampleImage2} alt="image" />
						</div>
						<div className="main-about__image main-about__image--small">
							<img src={exampleImage1} alt="image" />
						</div>
					</div>
					<div className="main-about__text">
						<Title size="middle" className="main-about__title">
							{t('main.about.title')}
						</Title>
						<p className="main-about__info">
							{t('main.about.description.first-part')}
						</p>
						<p className="main-about__info">
							{t('main.about.description.second-part')}
						</p>
						<Link
							to={ABOUT}
							color="blue"
							className="main-about__button button-link button-link--red"
						>
							{t('main.buttons.read-more')}
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default MainAbout;
