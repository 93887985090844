import React, { ReactNode } from 'react';
import sprite from '../img/icons/spriteServices.svg';

interface propsServicesCard {
	children?: ReactNode;
	icon?: string;
	className?: string;
	list: string[];
	subList?: string[];
}

function ServicesCard({
	children,
	icon,
	className,
	list,
	subList,
}: propsServicesCard) {
	return (
		<li className={`services-card ${className}`}>
			<div className="services-card__body">
				<div className="services-card__left">
					<svg className="services-card__icon">
						<use href={sprite + '#' + icon} />
					</svg>
					<div className="services-card__text">{children}</div>
				</div>
				<div className="services-card__right">
					<ul>
						{list?.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
					{subList && (
						<ul className="services-card__sub-title">
							{subList?.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</li>
	);
}

export default ServicesCard;
