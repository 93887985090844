import { useCallback } from 'react';
import useHttp from './httpService';

const useProjects = () => {
	const { request, getAll100 } = useHttp();
	const _baseUrl = `${process.env.REACT_APP_API_URL}`	
	const getAllProjects = (language ='ua') => getAll100('project', 'GET', null,
	{'Accept-Language': `${language}`, 
	'X-Workspace': 'uaep',
	'Access-Control-Allow-Origin': `${_baseUrl}`});

	const getProject = useCallback(
		async (projectId: string, language='ua') =>  await request(`/project/translation/${projectId}/get`, 'GET', null,
			{'Accept-Language': `${language}`, 
			'X-Workspace': 'uaep',
			'Access-Control-Allow-Origin': `${_baseUrl}`})
		,
		[request]
	);

	return { getAllProjects, getProject };
};

export default useProjects;
