import { useEffect, useState, useRef } from 'react';
import useDocuments from '../services/service.documents';
import useStore from '../store/selectors/useStore';
import { documentsActions } from '../store/slices/documentsSlice';
import useBindActionCreators from '../hooks/useBindActionCreators';
import React from 'react';
import AboutFileItem from './AboutFileItem';
import Title from './Title';
import MoreControls from './MoreControls';
import { documents } from '../typings/documents';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function AboutFile() {
	const { t } = useTranslation();
	const { documents, siteDocument, language } = useStore();
	const { getAllDocuments } = useDocuments();
	const { setDocuments, setSiteDocument } =
		useBindActionCreators(documentsActions);
	const documentHandlerHeight = useRef(null);
	const [handlerHeight, setHandlerHeight] = useState(Number);

	const [openedDocuments, setOpenedDocuments] = useState<documents | null>(
		null
	);

	const displayedDocuments = openedDocuments?.map((item) => (
		<AboutFileItem key={item.id} className="about-file__item" document={item} />
	));
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`

useEffect(() => {
	if (language) {
		getAllDocuments(language, 'downloadable').then((data) =>
		setDocuments(
			data.documents
		)
	);
		getAllDocuments(language,'organizational_structure').then((data) =>
			setSiteDocument(
				data.documents
			)
		);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	useEffect(() => {
		setOpenedDocuments(
			documents.length >= 4 ? documents.slice(0, 4) : documents
		);
	}, [documents]);

	return (
		<section className="about-file">
			<div id="downloads" className="container">
				<Title size="middle" className="about-file__title">
					{t('about.downloads.title')}
				</Title>
				<div className="about-file__body">
					<ul
						className="about-file__list"
						style={{
							height: handlerHeight + 35,
						}}
					>
						<div ref={documentHandlerHeight} className="about-file__handler">
							{displayedDocuments}
						</div>
					</ul>
					<MoreControls
						handlerRef={documentHandlerHeight}
						openedItems={openedDocuments}
						handleOpenedItems={setOpenedDocuments}
						items={documents}
						handleBlockHeight={setHandlerHeight}
					/>
					<a
						id="structure"
						target="_blank"
						rel="noreferrer"
						href={`${_baseUrl}${siteDocument[0]?.file?.path}`}
						className="about-file__structure"
					>
						{t('about.downloads.main-doc')}
					</a>
				</div>
			</div>
		</section>
	);
}

export default AboutFile;
