import React from 'react';

import { useTranslation } from 'react-i18next';
import useStore from '../store/selectors/useStore';
import '../utils/i18next';

interface propsMainAboutInfo {
	className?: string;
}

function MainAboutInfo({ className }: propsMainAboutInfo) {
	const { language } = useStore();
	const { t } = useTranslation();

	return (
		<div className={'main-about-info ' + className}>
			<div className="main-about-info__body">
				<div className="main-about-info__block-title">
					{/* <span className="main-about-info__subtitle">
						{t('main.title.type')}
					</span>
					<h1 className="main-about-info__title">{t('main.title.name')}</h1> */}

					{language !== 'en' ? (
						<>
							<span className="main-about-info__subtitle">
								{t('main.title.type')}
							</span>
							<h1 className="main-about-info__title">{t('main.title.name')}</h1>
						</>
					) : (
						<>
							<h1 className="main-about-info__title">{t('main.title.name')}</h1>
							<span className="main-about-info__subtitle">
								{t('main.title.type')}
							</span>
						</>
					)}

					<div
						className={`main-about-info__description ${
							language === 'en' ? 'main-about-info__description--mt' : ''
						}`}
					>
						{t('main.description.first-part')}
						<br />
						{t('main.description.second-part')}
					</div>
				</div>
			</div>
		</div>
	);
}

export default MainAboutInfo;
