import { useCallback } from 'react';
import useHttp from './httpService';

const useDocuments = () => {
	const _baseUrl = `${process.env.REACT_APP_API_URL}`
	const {  getAllDoc } = useHttp();
	const getAllDocuments = useCallback(
		( language = 'uk', type: string) => getAllDoc('document', 'GET', null, type,
		{'Accept-Language': `${language}`, 
		'X-Workspace': `uaep`,
		'Access-Control-Allow-Origin': `${_baseUrl}`}),
		[getAllDoc]
	);

	return { getAllDocuments };
};

export default useDocuments;
