import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NEWS } from '../helpers/routes';
import MainNewsList from './MainNewsList';
import MainNewsPriority from './MainNewsPriority';
import Title from './Title';

import useBindActionCreators from '../hooks/useBindActionCreators';
import useNews from '../services/service.news';
import { setNews } from '../store/slices/newsSlice';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';

interface propsMainNews {
	className?: string;
}

function MainNews({ className }: propsMainNews) {
	const { t } = useTranslation();
	const { language } = useStore();
	const { getAllNewsWithPagination } = useNews();
	const { setNews: dispatchNews } = useBindActionCreators({ setNews });

	useEffect(() => {
		getAllNewsWithPagination(1, language).then((data) => dispatchNews(data.articles));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<section className={`${className} main-news`}>
			<div className="container">
				<div className="main-news__wrapper">
					<div className="main-news__header">
						<Title size="middle" className="main-news__title">
							{t('main.news.title')}
						</Title>
						<Link
							to={NEWS}
							className="main-news__button button-link button-link--red"
						>
							{t('main.news.see-all')}
						</Link>
					</div>

					<div className="main-news__body">
						<div className="main-news__body-wrapper">
							<MainNewsPriority className="main-news__priority" />
							<MainNewsList className="main-news__list" />
							<Link
								to={NEWS}
								className="main-news__button main-news__button--mobile button-link button-link--red"
							>
								{t('main.news.see-all')}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default MainNews;
